export namespace Companies {
  export interface ICompanyData {
    id: number;
    corporateName: string;
    fantasyName: string;
    nickname: string
    imageUrl: string;
  }

  export class CompanyData implements ICompanyData {
    id: number = 0;
    corporateName: string = "";
    fantasyName: string = "";
    nickname: string = ""
    imageUrl: string = "";
  }
}
