import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  lang: {
    locales: {
      'pt-BR': {
        dataIterator: {
          rowsPerPageText: 'Registros por pagina:',
          rowsPerPageAll: 'Todos',
          pageText: '{0}-{1} of {2}',
          noResultsText: 'Sem registros',
          nextPage: 'Próxima',
          prevPage: 'Anterior',
        },
        dataTable: {
          rowsPerPageText: 'Linhas por página:',
        },
        noDataText: 'Sem registros',
      },
    },
    current: 'pt-BR',
  },

  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#335275', //'#b00ffc', #702C91
        secondary: '#F25F49', //#FFF0D7, 0d1833
        terciary: '#F6ECE8', //FFAD02
        accent: '#ACBFD7',
        error: '#F25F48',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
})