import { Constants } from "@/constants";
import Vue from "vue";
import store from "@/store/store";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const LocaleComponent = {
  render(h: any) {
    return h("router-view");
  },
};

export const ROUTERS = {
  ACCESS_CONTROL: {
    LOGIN: {
      PATH: "/login",
      NAME: "Acesse sua conta",
      ICON: "login",
    },
    LOGOUT: {
      PATH: "/logout",
      NAME: "Acesse sua conta",
      ICON: "login",
    },
    EXPIRED_SESSION: {
      PATH: "/expiredSession",
      NAME: "Sessão Expirada",
      ICON: "update",
    },
    NEWACCOUNT: {
      NEW_INDUSTRY: {
        PATH: "/newIndustry",
        NAME: "Cadastro de Indústria",
        ICON: "factory",
      },

      NEW_CLIENT_ACCOUNT: {
        PATH: "/newClientAccount",
        NAME: "Criar conta",
        ICON: "person_add",
      },
      NEW_CLIENT_PARTNER_ACCOUNT: {
        PATH: "/newAccount",
        NAME: "Criar conta Comercial",
        ICON: "person_add",
      },
    },
  },
  PROFILE: {
    CLIENT: {
      PATH: "/myProfile",
      NAME: "Meu Perfil",
      ICON: "manage_accounts",
    },
    BUSINESS_UNIT: {
      PATH: "/partnerProfile",
      NAME: "Configurações da empresa",
      ICON: "business",
    },
    PET: {
      PATH: "/my_pet_profile",
      NAME: "Meus Pets",
      ICON: "pets",
    },
    USER_PUBLIC_PROFILE: {
      PATH: "/user/:searchContent",
      NAME: "/user/:searchContent",
      ICON: "account_circle",
    },
  },
  HOME: {
    DEFAULT: {
      PATH: "/",
      NAME: "Início",
      ICON: "home",
    },
  },
  PRODUCTS: {
    DEFAULT: {
      PATH: "/products",
      NAME: "Produtos",
      ICON: "inventory_2",
    },
  },
  MARKETING: {
    DEFAULT: {
      PATH: "/marketing",
      NAME: "Marketing",
      ICON: "point_of_sale",
    },
  },
  DISTRIBUCTORS: {
    DEFAULT: {
      PATH: "/distribuctors",
      NAME: "Distribuidores",
      ICON: "local_shipping",
    },
  },
  RELEASES: {
    DEFAULT: {
      PATH: "/releases",
      NAME: "Lançamentos / Insersões",
      ICON: "grade",
    },
  },

  INSTITUTIONAL: {
    ABOUT: {
      DEFAULT: {
        PATH: "/whois",
        NAME: "Quem é PetAoLado.com",
        ICON: "pets",
      },
    },
    BELIEVE: {
      DEFAULT: {
        PATH: "/believe",
        NAME: "Cremos",
        ICON: "question_answer",
      },
    },
    THE_SW: {
      DEFAULT: {
        PATH: "/the_sw",
        NAME: "A SoluftWare///",
        ICON: "question_answer",
      },
    },
  },
  CONTACTUS: {
    DEFAULT: {
      PATH: "/contactUs",
      NAME: "Fale com a PetAoLado.com",
      ICON: "question_answer",
    },
  },

  FOOTER_MENU: {
    WORK: {
      PATH: "/workwithus",
      NAME: "Trabalhe conosco",
      ICON: "person",
    },
    CONDITIONS: {
      PATH: "/conditions",
      NAME: "Termos e Condições",
      ICON: "business",
    },
    PRIVACITY: {
      PATH: "/privacity",
      NAME: "Política de Privacidade PetAoLado.com",
      ICON: "pets",
    },
  },

  POLICIES: {
    PRIVACY: {
      PATH: "/privacity",
      NAME: "Privacidade",
      ICON: "",
    },
    COOKIES: {
      PATH: "/cookies",
      NAME: "Cookies",
      ICON: "",
    },
  },

  SEARCH: {
    DEFAULT: {
      PATH: "/searchContent/:searchContent/:searchType",
      NAME: "/searchContent/:searchContent/:searchType",
      ICON: "pets",
    },
  },

  ACTIVATION: {
    DEFAULT: {
      PATH: "/industryActivation",
      NAME: "Ativação da Indústria",
      ICON: "verified_user",
    },
  },

  ADMINISTRATIVE_USERS: {
    DEFAULT: {
      PATH: "/administrativeUsers",
      NAME: "Usuários Administrativos",
      ICON: "group",
    },
  },
};

const routes: Array<RouteConfig> = [
  {
    path: ROUTERS.ACCESS_CONTROL.LOGIN.PATH,
    name: ROUTERS.ACCESS_CONTROL.LOGIN.NAME,
    component: () => import("@/views/login/login"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ACCESS_CONTROL.LOGOUT.PATH,
    name: ROUTERS.ACCESS_CONTROL.LOGOUT.NAME,
    component: () => import("@/views/account/logout"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ACCESS_CONTROL.NEWACCOUNT.NEW_INDUSTRY.PATH,
    name: ROUTERS.ACCESS_CONTROL.NEWACCOUNT.NEW_INDUSTRY.NAME,
    component: () => import("@/views/account/newCommercialUserAccount"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ACCESS_CONTROL.EXPIRED_SESSION.PATH,
    name: ROUTERS.ACCESS_CONTROL.EXPIRED_SESSION.NAME,
    component: () => import("@/views/account/expiredSession"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PROFILE.CLIENT.PATH,
    name: ROUTERS.PROFILE.CLIENT.NAME,
    component: () => import("@/views/profile/myProfile"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PROFILE.BUSINESS_UNIT.PATH,
    name: ROUTERS.PROFILE.BUSINESS_UNIT.NAME,
    component: () => import("@/views/businessUnits/businessUnitProfile"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.HOME.DEFAULT.PATH,
    name: ROUTERS.HOME.DEFAULT.NAME,
    component: () => import("@/views/home/home"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.PRODUCTS.DEFAULT.PATH,
    name: ROUTERS.PRODUCTS.DEFAULT.NAME,
    component: () => import("@/views/products/companyProducts"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.MARKETING.DEFAULT.PATH,
    name: ROUTERS.MARKETING.DEFAULT.NAME,
    component: () => import("@/views/marketing/companyMarketing"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.DISTRIBUCTORS.DEFAULT.PATH,
    name: ROUTERS.DISTRIBUCTORS.DEFAULT.NAME,
    component: () => import("@/views/distribuctors/companyDistribuctors"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.RELEASES.DEFAULT.PATH,
    name: ROUTERS.RELEASES.DEFAULT.NAME,
    component: () => import("@/views/releases/companyReleases"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.INSTITUTIONAL.ABOUT.DEFAULT.PATH,
    name: ROUTERS.INSTITUTIONAL.ABOUT.DEFAULT.NAME,
    component: () => import("@/views/several/about"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.CONTACTUS.DEFAULT.PATH,
    name: ROUTERS.CONTACTUS.DEFAULT.NAME,
    component: () => import("@/views/several/contactUs"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.INSTITUTIONAL.THE_SW.DEFAULT.PATH,
    name: ROUTERS.INSTITUTIONAL.THE_SW.DEFAULT.NAME,
    component: () => import("@/views/several/the_sw"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.INSTITUTIONAL.BELIEVE.DEFAULT.PATH,
    name: ROUTERS.INSTITUTIONAL.BELIEVE.DEFAULT.NAME,
    component: () => import("@/views/several/believe"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.FOOTER_MENU.CONDITIONS.PATH,
    name: ROUTERS.FOOTER_MENU.CONDITIONS.NAME,
    component: () => import("@/views/several/conditions"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.FOOTER_MENU.PRIVACITY.PATH,
    name: ROUTERS.FOOTER_MENU.PRIVACITY.NAME,
    component: () => import("@/views/several/privacity"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.SEARCH.DEFAULT.PATH,
    name: ROUTERS.SEARCH.DEFAULT.NAME,
    component: () =>
      import("@/views/marketPet/components/appSearch/searchResult"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.ACTIVATION.DEFAULT.PATH,
    name: ROUTERS.ACTIVATION.DEFAULT.NAME,
    component: () => import("@/views/businessUnits/businessUnitActivation"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ADMINISTRATIVE_USERS.DEFAULT.PATH,
    name: ROUTERS.ADMINISTRATIVE_USERS.DEFAULT.NAME,
    component: () => import("@/views/users/administrativeUsers"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
];

const router = new VueRouter({
  // mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.getters.currentSessionUser) {
    const PersonType = store.getters.currentSessionUser;
    const allowedRoles = (to.meta as { roles: string[] }).roles || [];
    const userRole = PersonType.personType;
    if (allowedRoles.indexOf(userRole) === -1) {
      next("/unauthorized");
    } else {
      next();
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      let content = localStorage.getItem("user");

      if (content != null) {
        let user = JSON.parse(content);
        if (to.matched.some((record) => record.meta.is_admin)) {
          if (user.is_admin == 1) {
            next();
          } else {
            next({ name: "home" });
          }
        } else {
          next();
        }
      }
    }
  } else {
    next();
  }
});

export default router;
