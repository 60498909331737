import { Constants } from "@/constants";

export const JWT = "token";
export const APP_SET_MENU = "false";
export const APP_SET_NAVBAR = "true";
export const APP_SET_LOGIN = "true";
export const SHOW_SNACK = 'showSnack'
export const SHOW_SNACK_ERROR = 'showSnackError'
export const HIDE_SNACK = 'hideSnack'

export const SESSION_SEVERAL_DATA = null;
export const SESSION_USER_DATA = null;
export const SESSION_USER_ADDRESS = null;
export const SESSION_BUSINESS_UNIT = null;
export const SESSION_PERSON_TYPE = Constants.Enums.PERSONTYPE.NO_USER;
export const SESSION_NAVIGATION_TYPE = Constants.Enums.NAVIGATION_TYPE.ANONYMOUS;