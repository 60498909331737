import { Companies } from "./Companies";
import { Contacts } from "./IContacts";
import { Addresses } from "./IAdressess";
import { Documents } from "./IDocuments";
import { BusinessUnitParameters } from "./IParameters";
import { Persons } from "./IPersons";
import { Deliveries } from "./IBusinessUnitDeliveryConfig";

export namespace BusinessUnits {
  export interface IBusinessUnit {
    id: number;
    companyDataId: number;
    companyData: Companies.ICompanyData;
    aboutBusinessUnit: IAboutBusinessUnit;
    businessUnitHistory: IBusinessUnitHistory;
    addressId: number;
    addresses: Addresses.IAddress[];
    documents: Documents.IDocument[];
    contacts: Contacts.IContact[];
    parametersValues: BusinessUnitParameters.IBusinessUnitParameterValue[];
    businessUnitPersons: Persons.IPerson[];
    offerType: number;
    status: number;
    distance: string;
    distanceValue: number;
    isOnline: boolean;
    deliveryConfigs: Deliveries.IBusinessUnitDeliveryConfig[];
    businessUnitMembershipPlan: IBusinessUnitMembershipPlan;
    storeConfigs: IBusinessUnitStoreConfigs;
    mainBusinessUnitId: number;
    businessUnitBranchs: any;
    count: number;
    type: number;
    industryId: number;
  }

  export class BusinessUnit implements IBusinessUnit {
    id: number = 0;
    companyDataId: number = 0;
    companyData: Companies.ICompanyData = new Companies.CompanyData();
    aboutBusinessUnit: IAboutBusinessUnit = new AboutBusinessUnit();
    businessUnitHistory: IBusinessUnitHistory = new BusinessUnitHistory();
    addressId: number = 0;
    addresses: Addresses.IAddress[] = new Array<Addresses.Address>();
    documents: Documents.IDocument[] = new Array<Documents.IDocument>();
    contacts: Contacts.IContact[] = new Array<Contacts.IContact>();
    parametersValues: BusinessUnitParameters.IBusinessUnitParameterValue[] =
      new Array<BusinessUnitParameters.BusinessUnitParameterValue>();
    businessUnitPersons: Persons.IPerson[] = new Array<Persons.IPerson>();
    offerType: number = 0;
    status: number = 0;
    distance: string = "";
    distanceValue: number = 0;
    isOnline: boolean = false;
    deliveryConfigs: Deliveries.IBusinessUnitDeliveryConfig[] =
      new Array<Deliveries.IBusinessUnitDeliveryConfig>();
    businessUnitMembershipPlan: IBusinessUnitMembershipPlan =
      new BusinessUnitMembershipPlan();
    storeConfigs: IBusinessUnitStoreConfigs = new BusinessUnitStoreConfigs();
    mainBusinessUnitId: number = 0;
    businessUnitBranchs: any;
    count: number = 0;
    type: number = 0;
    industryId: number = 0;
  }

  export interface IUpdateBusinessUnitRequest {
    id: number;
    companyDataId: number;
    companyData: Companies.ICompanyData;
    aboutBusinessUnit: IAboutBusinessUnit;
    businessUnitHistory: IBusinessUnitHistory;
    addressId: number;
    addresses: Addresses.IAddress[];
    documents: Documents.IDocument[];
    contacts: Contacts.IContact[];
    parametersValues: BusinessUnitParameters.IBusinessUnitParameterValue[];
    businessUnitPersons: Persons.IPerson[];
    offerType: number;
    status: number;
    distance: string;
    distanceValue: number;
    isOnline: boolean;
    deliveryConfigs: Deliveries.IBusinessUnitDeliveryConfig[];
    storeConfigs: IBusinessUnitStoreConfigs;
    mainBusinessUnitId: number;
  }

  export class UpdateBusinessUnitRequest implements IUpdateBusinessUnitRequest {
    id: number = 0;
    companyDataId: number = 0;
    companyData: Companies.ICompanyData = new Companies.CompanyData();
    aboutBusinessUnit: IAboutBusinessUnit = new AboutBusinessUnit();
    businessUnitHistory: IBusinessUnitHistory = new BusinessUnitHistory();
    addressId: number = 0;
    addresses: Addresses.IAddress[] = new Array<Addresses.Address>();
    documents: Documents.IDocument[] = new Array<Documents.IDocument>();
    contacts: Contacts.IContact[] = new Array<Contacts.IContact>();
    parametersValues: BusinessUnitParameters.IBusinessUnitParameterValue[] =
      new Array<BusinessUnitParameters.BusinessUnitParameterValue>();
    businessUnitPersons: Persons.IPerson[] = new Array<Persons.IPerson>();
    offerType: number = 0;
    status: number = 0;
    distance: string = "";
    distanceValue: number = 0;
    isOnline: boolean = false;
    deliveryConfigs: Deliveries.IBusinessUnitDeliveryConfig[] =
      new Array<Deliveries.IBusinessUnitDeliveryConfig>();
    storeConfigs: IBusinessUnitStoreConfigs = new BusinessUnitStoreConfigs();
    mainBusinessUnitId: number = 0;
  }

  export interface IAboutBusinessUnit {
    id: number;
    businessUnitId: number;
    content: string;
    creationDate: string;

    creationUserId: number;
    updateDate: string;
    updateUserId: number;
    deletionDate: string;
    deletionUserId: number;
  }

  export class AboutBusinessUnit implements IAboutBusinessUnit {
    id: number = 0;
    businessUnitId: number = 0;
    content: string = "";

    creationDate: string = "";
    creationUserId: number = 0;
    updateDate: string = "";
    updateUserId: number = 0;
    deletionDate: string = "";
    deletionUserId: number = 0;
  }

  export interface IBusinessUnitHistory {
    id: number;
    businessUnitId: number;
    content: string;

    creationDate: string;
    creationUserId: number;
    updateDate: string;
    updateUserId: number;
    deletionDate: string;
    deletionUserId: number;
  }

  export class BusinessUnitHistory implements IBusinessUnitHistory {
    id: number = 0;
    businessUnitId: number = 0;
    content: string = "";

    creationDate: string = "";
    creationUserId: number = 0;
    updateDate: string = "";
    updateUserId: number = 0;
    deletionDate: string = "";
    deletionUserId: number = 0;
  }

  export interface IBusinessUnitPerson {
    businessUnitId: number;
    personId: number;
    personType: number;
  }

  export class BusinessUnitPerson implements IBusinessUnitPerson {
    businessUnitId: number = -1;
    personId: number = -1;
    personType: number = -1;
  }

  export interface INewBusinessUnitPersonRequest {
    personId: number;
    businessUnitId: number;
    businessUnitPersonType: number;
    mailAddress: string;
  }

  export class NewBusinessUnitPersonRequest
    implements INewBusinessUnitPersonRequest
  {
    personId: number = -1;
    businessUnitId: number = -1;
    businessUnitPersonType: number = -1;
    mailAddress: string = "";
  }

  export interface IRemoveBusinessUnitPersonRequest {
    personId: number;
    businessUnitId: number;
    userId: number;
  }

  export class RemoveBusinessUnitPersonRequest
    implements IRemoveBusinessUnitPersonRequest
  {
    personId: number = -1;
    businessUnitId: number = -1;
    userId: number = -1;
  }

  export interface IBusinessUnitStoreConfigs {
    id: number;
    businessUnitId: number;
    headerImageUrl: string;
    headerImagePublicId: string;
    primaryColor: string;
    secondaryColor: string;
    showProductsTab: boolean;
    showServicesTab: boolean;
    showAdoptTab: boolean;
    showAboutTab: boolean;
    showPublicationsTab: boolean;
    showFollowersTab: boolean;
    showTeamTab: boolean;
    showEvaluationsTab: boolean;

    creationDate: string;
    creationUserId: number;
    updateDate: string;
    updateUserId: number;
    deletionDate: string;
    deletionUserId: number;
  }

  export class BusinessUnitStoreConfigs implements IBusinessUnitStoreConfigs {
    id: number = 0;
    businessUnitId: number = 0;
    headerImageUrl: string =
      "https://res.cloudinary.com/petaomeuladocombr/image/upload/v1698607213/petaomeulado/businessunitslogos/yb96fhljax2lezaeil3a.png";
    headerImagePublicId: string = "";
    primaryColor: string = "#335275";
    secondaryColor: string = "#F25F49";
    showProductsTab: boolean = true;
    showServicesTab: boolean = true;
    showAdoptTab: boolean = true;
    showAboutTab: boolean = true;
    showPublicationsTab: boolean = true;
    showFollowersTab: boolean = true;
    showTeamTab: boolean = true;
    showEvaluationsTab: boolean = true;

    creationDate: string = "";
    creationUserId: number = 0;
    updateDate: string = "";
    updateUserId: number = 0;
    deletionDate: string = "";
    deletionUserId: number = 0;
  }

  export interface IBankingInstitution {
    id: number;
    bankCode: string;
    fantasyName: string;

    creationDate: string;
    creationUserId: number;
    updateDate: string;
    updateUserId: number;
    deletionDate: string;
    deletionUserId: number;
  }

  export class BankingInstitution implements IBankingInstitution {
    id: number = 0;
    bankCode: string = "";
    fantasyName: string = "";

    creationDate: string = "";
    creationUserId: number = 0;
    updateDate: string = "";
    updateUserId: number = 0;
    deletionDate: string = "";
    deletionUserId: number = 0;
  }

  export interface IBusinessUnitBankingData {
    id: number;
    businessUnitId: number;
    bankCode: string;
    bankBranch: string;
    accountNumber: string;
    accountDigit: string;

    creationDate: string;
    creationUserId: number;
    updateDate: string;
    updateUserId: number;
    deletionDate: string;
    deletionUserId: number;
  }

  export class BusinessUnitBankingData implements IBusinessUnitBankingData {
    id: number = 0;
    businessUnitId: number = 0;
    bankCode: string = "";
    bankBranch: string = "";
    accountNumber: string = "";
    accountDigit: string = "";
    creationDate: string = "";
    creationUserId: number = 0;
    updateDate: string = "";
    updateUserId: number = 0;
    deletionDate: string = "";
    deletionUserId: number = 0;
  }

  export interface IShareBusinessUnit {
    userId: number;
    buName: string | undefined;
    buUrl: string;
    recipient: IContactPerson;
    issuer: IContactPerson;
  }

  export interface IContactPerson {
    name: string;
    email: string;
    mobileNumber: string;
  }

  export class ShareBusinessUnit implements IShareBusinessUnit {
    userId: number = 0;
    buName: string = "";
    buUrl: string = "";
    recipient: IContactPerson = new ContactPerson();
    issuer: IContactPerson = new ContactPerson();
  }

  export class ContactPerson implements IContactPerson {
    name: string = "";
    email: string = "";
    mobileNumber: string = "";
  }

  export class BusinessUnitActivationStatus {
    principalData = new PrincipalData();
    productsAndServices: boolean = false;
    operationParameters: boolean = false;
    bankingData: boolean = false;
    monthly: boolean = false;
  }

  export class PrincipalData {
    address: boolean = false;
    contacts: boolean = false;
  }

  export interface IBusinessUnitMembershipPlan {
    businessUnitId: number;
    membershipPlanId: number;
    planStatus: number;
    dueDate: number;
  }

  export class BusinessUnitMembershipPlan
    implements IBusinessUnitMembershipPlan
  {
    businessUnitId: number = 0;
    membershipPlanId: number = 0;
    planStatus: number = 0;
    dueDate!: number;
  }
}
