export namespace Constants.Enums {
  export const USERSTATUS = {
    Inactive: 0,
    PendingActivate: 1,
    Active: 2,
  };

  export const BUSINESSUNITSTATUS = {
    INACTIVE: 0,
    PENDING_ACTIVATE: 1,
    ACTIVE: 2,
    PENDING_PAYMENT: 3,
  };

  export const BUSINESSUNIT_ACTIVATION_REQUEST_STATUS = {
    REQUESTED: 0,
    APPROVED: 1,
    REPPROVED: 2,
  };

  export const BUSINESSUNITTYPE = {
    SHOPKEEPER: 0,
    DISTRIBUCTOR: 1,
    INDUSTRY: 2,
  };

  export const BUSINESSUNIT_MEMBERSHIP_PLAN_STATUS = {
    INACTIVE: 0,
    PENDING_ACTIVATE: 1,
    ACTIVE: 2,
    PENDING_PAYMENT: 3,
  };

  export const APPLICATIONSTATUS = {
    InManteinance: 0,
    Running: 1,
  };

  export const PERSONTYPE = {
    ADMINISTRATIVE_USER: 0,
    CLIENT: 1,
    BUSINESS_UNIT: 2,
    NO_USER: 3,
  };

  export const SHARE_TYPE = {
    BUSINESSUNIT: 0,
    GROUP: 1,
    PROFILE: 2,
    PRODUCT: 3,
    SERVICE: 4,
    POST: 5,
  };

  export const ADM_USER_PAYMENT = [3];

  export const CREDENTIALTYPE = {
    EMail: 0,
    Document: 1,
    NickName: 2,
  };

  export const DOCUMENTTYPE = {
    CPF: 1,
    RG: 2,
    CNPJ: 3,
    IE: 4,
  };

  export const CONTACTTYPE = {
    CellPhone: 1,
    Landline: 2,
    MailAddress: 3,
    Whatsapp: 4,
  };

  export const DOCUMENTS = [
    { value: 1, description: "CPF" },
    { value: 2, description: "RG" },
    { value: 3, description: "CNPJ" },
    { value: 4, description: "IE" },
  ];

  export const CONTACTS = [
    { value: 1, description: "Celular" },
    { value: 2, description: "Telefone Fixo" },
    { value: 3, description: "E-Mail" },
    { value: 4, description: "Whatsapp" },
  ];

  export const MARITALSTATUS = {
    Single: 0,
    Married: 1,
    Divorced: 2,
    Widower: 3,
    Loving: 4,
  };

  export const GENRE = [
    { value: 0, description: "Masculino" },
    { value: 1, description: "Feminino" },
    { value: 2, description: "Prefiro não informar" },
  ];

  export const OfferType = [
    { value: 1, description: "Produtos" },
    { value: 2, description: "Serviços" },
    { value: 3, description: "Ambos" },
  ];

  export const BUSINESS_UNIT_PERSON_TYPE = [
    { value: 1, description: "Sócio/Fundador" },
    { value: 2, description: "Sócio" },
    { value: 3, description: "Colaborador" },
  ];

  export const BUSINESSUNIT_PERSONTYPE = {
    OWNER: 1,
    PARTNER: 2,
    EMPOYEE: 3,
  };

  export const BUSINESSUNIT_PROFILE_OPTIONS = {
    ANALYTICS: 0,
    DESIGN: 1,
    PRINCIPAL_DATA: 2,
    ABOUT_HISTORY: 3,
    PRODUCT_MIX: 4,
    INSERTS: 5,
    REPRESENTATIVES: 6,
    ORDERS: 7,
    SERVICE_MIX: 8,
    ATENDANTS: 9,
    SCHEDULE: 10,
    ADOPET: 11,
    MARKETING_ACTIONS: 12,
    ADMINISTRATIVE_USERS: 13,
    PARAMETERS_CONFIG: 14,
    BANKING_DATA: 15,
    DELIVERY_CONFIG: 16,
    MEMBERSHIP_PLANS: 17,
  };

  export const BUSINESSUNIT_PROFILE_OPTIONS_TITLES = [
    { id: 0, description: "Analytics" },
    { id: 1, description: "Design" },
    { id: 2, description: "Dados Principais" },
    { id: 3, description: "Sobre" },
    { id: 4, description: "Mix de Produtos" },
    { id: 5, description: "Inserções" },
    { id: 6, description: "Representantes" },
    { id: 7, description: "Meus Pedidos" },
    { id: 8, description: "Mix de Serviços" },
    { id: 9, description: "Atendentes" },
    { id: 10, description: "Agenda" },
    { id: 11, description: "AdoPet" },
    { id: 12, description: "Ações de Marketing" },
    { id: 13, description: "Usuários Administrativos" },
    { id: 14, description: "Parâmetros de Operação" },
    { id: 15, description: "Dados Bancários" },
    { id: 16, description: "Delivery" },
    { id: 17, description: "Planos de Adesão" },
  ];

  export const PRODUCTOFFERTYPE = {
    PRODUCT: 1,
    SERVICE: 2,
    BOTH: 3,
  };

  export const CITIES = [
    { id: 1, description: "RO" },
    { id: 2, description: "AC" },
    { id: 3, description: "AM" },
    { id: 4, description: "RR" },
    { id: 5, description: "PA" },
    { id: 6, description: "AP" },
    { id: 7, description: "TO" },
    { id: 8, description: "MA" },
    { id: 9, description: "PI" },
    { id: 10, description: "CE" },
    { id: 11, description: "RN" },
    { id: 12, description: "PB" },
    { id: 13, description: "PE" },
    { id: 14, description: "AL" },
    { id: 15, description: "SE" },
    { id: 16, description: "BA" },
    { id: 17, description: "MG" },
    { id: 18, description: "ES" },
    { id: 19, description: "RJ" },
    { id: 20, description: "SP" },
    { id: 21, description: "PR" },
    { id: 22, description: "SC" },
    { id: 23, description: "RS" },
    { id: 24, description: "MS" },
    { id: 25, description: "MT" },
    { id: 26, description: "GO" },
    { id: 27, description: "DF" },
  ];

  export const COUNTRIES = [{ id: 1, description: "BRASIL" }];

  export const SEARCHRESPONSE = {
    TYPE: {
      USER: 0,
      PERSON: 1,
    },
  };

  export const NOTIFICATIONTYPE = {
    NONE: 0,
    INTERNAL: 1,
    EMAIL: 2,
    SMS: 3,
    WHATSAPP: 4,
    PUSH: 5,
    ALL: 6,
  };

  export const PETTYPE = [
    { value: -1, description: "" },
    { value: 0, description: "Cachorro" },
    { value: 1, description: "Gato" },
    { value: 2, description: "Pássaro" },
    { value: 3, description: "Hamster" },
    { value: 4, description: "Tartaruga" },
  ];

  export const PET_GENRE = [
    { value: -1, description: "" },
    { value: 0, description: "Macho" },
    { value: 1, description: "Fêmea" },
  ];
  export const PET_SIZE = [
    { value: -1, description: "" },
    { value: 0, description: "Pequeno" },
    { value: 1, description: "Médio" },
    { value: 2, description: "Grande" },
  ];

  export const CASTRATED = [
    { value: false, description: "Não" },
    { value: true, description: "Sim" },
  ];

  export const IMAGETYPE = {
    TYPE: {
      PRODUCT: 0,
      BUSINESSUNITLOGO: 1,
      PET: 2,
      PROFILE: 3,
      GROUP: 4,
      PUBLICATION: 5,
      PHOTOALBUM: 6,
      SERVICE: 7,
      DEFAULT: 8,
      EVALUATION: 9,
      HEADERSTORE: 10,
    },
  };

  export const IMAGETRANSACTIONTYPE = {
    TYPE: {
      UPDATE: 0,
      SELECTONLY: 1,
    },
  };

  export const GROUPPERSONTYPE = {
    TYPE: {
      OWNER: 0,
      ADMINISTRATIVEMEMBER: 1,
      MEMBER: 2,
    },
  };

  export const EXPOSURELEVELLIST = {
    LEVEL: {
      PUBLIC: 0,
      PRIVATE: 1,
    },
  };

  export const EXPOSURELEVEL = [
    { value: 0, description: "Público" },
    { value: 1, description: "Particular" },
  ];

  export const POST = {
    TYPE: {
      PHOTO: 0,
      VIDEO: 1,
      EVENT: 2,
      ARTICLE: 3,
    },
  };

  export const REACTION = {
    TYPE: {
      LIKE: 0,
      LOVE: 1,
      CONGRATS: 2,
      SMILE: 3,
      SAD: 4,
      HATE: 5,
    },
  };

  export const CATEGORIES = {
    LEVEL: {
      CATEGORIE: 0,
      SUBCATEGORIE_LEVEL1: 1,
      SUBCATEGORIE_LEVEL2: 2,
      SUBCATEGORIE_LEVEL3: 3,
    },
  };

  export const PRODUCTLISTTYPEOPTIONS = [
    { value: 0, description: "Genérico" },
    { value: 1, description: "Fazem sucesso entre os pets" },
    { value: 2, description: "Baseado nas suas últimas pesquisas" },
    { value: 3, description: "Achamos que podem te interessar" },
  ];

  export const PRODUCTLISTTYPE = {
    SEVERAL: 0,
    FEATURED: 1,
    SEARCHED: 2,
    SUGGESTION: 3,
  };

  export const SEARCHTYPE = {
    PRODUCT: 0,
    SERVICE: 1,
    SHOPKEEPER_PRODUCT: 2,
    USER: 3,
    GROUP: 4,
    SHOPKEEPER: 5,
    PRODUCTS_BY_CATEGORY: 6,
  };

  export const PARAMETERS_TYPES_VALUES = [
    { value: 0, description: "Selecione um tipo de parâmetro" },
    { value: 1, description: "Texto simples" },
    { value: 2, description: "Verdadeiro - Falso" },
    { value: 3, description: "Lista de Opções" },
    { value: 4, description: "De - Até" },
  ];

  export const PARAMETERS_TYPES = {
    SIMPLE_TEXT: 1,
    TRUE_OR_FALSE: 2,
    OPTIONS_LIST: 3,
    FROM_TO: 4,
  };

  export const MULTIPLE_OPTIONS = [
    { value: false, description: "Não" },
    { value: true, description: "Sim" },
  ];

  export const PARAMETER_INTERNAL_CODE = [
    { value: 0, description: "Selecione o código interno do parâmetro" },
    { value: 1, description: "Company_Slogan" },
    { value: 2, description: "Delivery_System" },
    { value: 3, description: "Week_Days" },
    { value: 4, description: "Week_Days_Opening_Hours" },
    { value: 5, description: "Weekend_Days" },
    { value: 6, description: "Saturday_Opening_Hours" },
    { value: 7, description: "Sunday_Opening_Hours" },
    { value: 8, description: "Delivery_Distance" },
    { value: 9, description: "Allow_Service_Scheduling" },
  ];

  export const PARAMETER_INTERNAL_CODE_VALUE = {
    COMPANY_SLOGAN: 1,
    DELIVERY_SYSTEM: 2,
    WEEK_DAYS: 3,
    WEEK_DAYS_OPENING_HOURS: 4,
    WEEKEND_DAYS: 5,
    SATURDAY_OPENING_HOURS: 6,
    SUNDAY_OPENING_HOURS: 7,
    DELIVERY_DISTANCE: 8,
    ALLOW_SERVICE_SCHEDULING: 9,
  };

  export const PARAMETER_VALUE_TYPE = [
    { value: 0, description: "Selecione o tipo de valores do parâmetro" },
    { value: 1, description: "Números" },
    { value: 2, description: "Horas" },
  ];

  export const NAVIGATION_TYPE = {
    USER: 0,
    COMPANY: 1,
    ANONYMOUS: 2,
  };

  export const VISUALIZATION_TYPES = {
    LIST: 0,
    MAP: 1,
  };

  export const DELIVERY_SYSTEM = [
    { value: 0, description: "Entrega Própria" },
    { value: 1, description: "Entrega PetAoLado.com" },
  ];

  export const CHARGE_TYPE = [
    { value: 0, description: "Valor Fixo" },
    { value: 1, description: "Valor por Km" },
  ];

  export const CONTACT_SUBJECT = [
    { id: 1, description: "Tenho uma sugestão" },
    { id: 2, description: "Tenho um elogio" },
    { id: 3, description: "Tenho um problema" },
  ];

  export const TYPEUSER = {
    TEAM_BU: 0,
    USER: 1,
  };

  export const ORDER_STATUS = [
    { value: 0, description: "Aguardando Emissão", color: "orange" },
    { value: 1, description: "Aguardando Confirmação", color: "orange" },
    { value: 2, description: "Confirmado", color: "green" },
    { value: 3, description: "Negado", color: "red" },
    { value: 4, description: "À Caminho", color: "green" },
    { value: 5, description: "Entregue", color: "green" },
    { value: 6, description: "Cancelado", color: "red" },
  ];

  export const ORDERSTATUS = {
    CREATED: 0,
    ISSUED: 1,
    CONFIRMED: 2,
    DISAPPROVED: 3,
    ONWAY: 4,
    DELIVERED: 5,
    CANCELED: 6,
  };

  export const PRODUCTSUGESTIONSTATUS = {
    Created: 0,
    RegistrationProcess: 1,
    Rejected: 2,
    Registered: 3,
  };

  export const PRODUCTSUGESTIONSTATUS_DESCRIPTION = [
    { value: 0, description: "Cadastro Pendente" },
    { value: 1, description: "Cadastro em Andamento" },
    { value: 2, description: "Cadastro não Efetuado" },
    { value: 3, description: "Cadastro Efetuado" },
  ];

  export const BUSINESSUNIT_INTERACTION_TYPE = {
    BUSINESSUNITACCESS: 0,
    BUSINESSUNITCONTACT: 1,
    BUSINESSUNITLINKSHARE: 2,
    PRODUCTACCESS: 3,
    SERVICEACCESS: 4,
    ADOPETACCESS: 5,
    CONTENTVISUALIZATION: 6,
    SHOPCARTPRODUCTADDED: 7,
    PRODUCTBUY: 8,
    SERVICESCHEDULE: 9,
    ADOPETINTEREST: 10,
    CONTENTSHARE: 11,
    ADOPETCONFIRMED: 12,
    ALL: 99,
  };

  export const BUSINESSUNIT_INTERACTION_TYPE_DESCRIPTION = [
    { value: 0, description: "Acesso à Loja" },
    { value: 1, description: "Falou com a empresa" },
    { value: 2, description: "Compartilhou o endereço da empresa" },
    { value: 3, description: "Acessou um produto da empresa" },
    { value: 4, description: "Acessou um serviço da empresa" },
    { value: 5, description: "Acessou um animal da empresa" },
    { value: 6, description: "Visualizou um conteúdo postado" },
    {
      value: 7,
      description: "Adicionou um produto da empresa no carrinho de compras",
    },
    { value: 8, description: "Comprou um produto da empresa" },
    { value: 9, description: "Agendou/Solicitou um serviço" },
    { value: 10, description: "Se interessou por um animal da empresa" },
    { value: 11, description: "Compartilhou um conteúdo da empresa" },
    { description: "", value: 99 },
  ];

  export const TIMELINE_TYPE = {
    SUGESTIONS: 0,
    FOLLOWED: 1,
    USER: 2,
    GROUP: 3,
    PUBLIC_USER: 4,
  };

  export const MEMBERSHIP_PLAN = {
    SHOPKEEPER: 1,
    PROVIDER: 2,
    ADOPET: 3,
  };

  export const MARKETPET_TABS = {
    PRODUCTS: 0,
    SERVICES: 1,
    ADOPET: 2,
  };
}

export namespace Constants.DefaultImages {
  export const DEFAULT_BUSINESS_UNIT_IMAGE =
    "https://res.cloudinary.com/petaomeuladocombr/image/upload/v1679957472/petaomeulado/defaultImages/wumko2gb9hbfqcvnouob.svg";
}
