//@ts-ignore
import App from "./App.vue";
import Vue from "vue";
import router from "@/router/router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
import { VNode } from "vue/types/umd";
import * as VueGoogleMaps from "vue2-google-maps";
import "./plugins/vue-currency-input";
import "./assets/styles/global.scss";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBNV1H4Rcb3MJsK_oPy8icg21isAnGfrqk",
  },
});

Vue.use(VueCookies);
Vue.$cookies.config('30d')
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h): VNode => h(App),
}).$mount("#app");

const EventBus = new Vue();
export default EventBus;