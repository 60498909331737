// @ts-nocheck
// @ts-ignore
import Vue from 'vue'
import VueCurrencyInput from 'vue-currency-input'

export class VueCurrencypluginOptions {
	/* see config reference
      https://github.com/vuejs-tips/v-money
     */
	static globalOptions: VueCurrencypluginOptions = {
		currency: null,
		autoDecimalMode: true,
		distractionFree: false,
		locale: 'pt-BR'
	}
}

Vue.use(VueCurrencyInput, VueCurrencypluginOptions)

export default VueCurrencyInput
